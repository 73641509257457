@font-face {
    font-family: Tiempos;
    src: url("../assets/fonts/TiemposHeadline-Light.otf") format("opentype"); }

@font-face {
    font-family: Universal;
    src: url("../assets/fonts/UniversalSans-v1-1-0-74-77-511112212111-500.ttf") format("truetype"); }

$sans: Universal;
$serif: Tiempos;

@import "./parts/checkbox";

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

body {
    margin: 15px; }

.App {
    width: 1000px;
    max-width: 100%;
    margin: auto;
    font-family: $sans;

    .loading {
        width: 100%;
        height: 100%;

        .spinner {
            position: absolute;
            transform: translate(-50%, -50%);
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            border: 5px solid black;
            width: 50px;
            height: 50px;
            border-bottom: 5px solid transparent;
            border-radius: 50%;
            animation: spin 0.6s linear infinite; } }

    .form {

        .heading {
            font-size: 40px;
            font-family: $serif;
            font-weight: 600; }

        .applicant {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                display: inline-block;
                width: calc(50% - 15px);
                padding-right: 15px;
                min-width: 350px;
                max-width: 100%;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    vertical-align: top;
                    width: 100px; }

                input {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% - 130px);
                    border: 1px solid black; } } }

        .details {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                .label {
                    width: 80px;
                    display: inline-block;
                    vertical-align: top; }

                textarea {
                    width: calc(100% - 86px);
                    display: inline-block;
                    vertical-align: top; } } }

        .description {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                margin-top: 10px;

                .label {
                    width: 120px;
                    display: inline-block;
                    vertical-align: top; }

                textarea {
                    width: calc(100% - 126px);
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black; } }

            .flat {
                .header {
                    font-family: $sans;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 30px 0px 0px; }

                .container {
                    width: 200px; }

                .input {
                    display: inline-block;
                    width: 50%;
                    min-width: 350px;

                    .label {
                        width: 240px;
                        vertical-align: middle; }

                    input[type="number"] {
                        padding: 10px;
                        width: 50px;
                        border: 1px solid black; }

                    textarea {
                        display: inline-block;
                        border: 1px solid black; } }

                .boolean {
                    margin: 20px 0px;

                    .label {
                        display: inline-block;
                        padding-right: 30px; }

                    .container {
                        width: 60px;
                        min-width: 60px; } } } }

        .age {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .break {
                display: inline-block;
                width: 30px; }

            .input {
                display: inline-block;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 307px); } } }

        .disclosure {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                input {
                    border: 1px solid black; }

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } } }

        .construction {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 320px);
                    min-width: 200px; } } }

        .accommodation {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .break {
                display: inline-block;
                width: 30px; }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 162px);
                    min-width: 200px; }

                input[type="number"] {
                    width: 23px;
                    height: 23px;
                    text-align: center; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield; } } }

        .services {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 126px);
                    min-width: 200px; } } }

        .land {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield; }

                input[type=text] {
                    width: 300px; } } }

        .tenure {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 142px);
                    min-width: 200px; } } }

        .property-details {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield;
                    width: 23px;
                    height: 23px;
                    text-align: center; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 167px);
                    min-width: 200px; } } }

        .features {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; } }

        .roads {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield;
                    width: 23px;
                    height: 23px;
                    text-align: center; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 167px);
                    min-width: 200px; } } }

        .rights {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                textarea {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 167px);
                    min-width: 200px; } } }

        .subsidence {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 0px;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input[type=text] {
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 167px);
                    min-width: 200px; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield;
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid black;
                    width: calc(100% - 167px);
                    width: 23px;
                    height: 23px;
                    text-align: center; } } }

        .solar-panels {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } } }

        .matters, .remarks {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            textarea {
                margin-top: 10px;
                width: 100%; } }

        .suitability {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .boolean {
                margin: 20px 0px;

                .label {
                    display: inline-block;
                    padding-right: 30px; }

                .container {
                    width: 60px;
                    min-width: 60px; } } }

        .valuation {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .input {
                display: inline-block;
                vertical-align: middle;
                margin: 10px 0px;
                width: 50%;

                .label {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top; }

                input {
                    width: 100px;
                    border: 1px solid black;
                    vertical-align: middle; }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; }

                input[type=number] {
                    -moz-appearance: textfield;
                    margin-left: -15px;
                    background-color: transparent;
                    padding-left: 20px;
                    width: 60px; } } }

        .desirability {
            border-top: 1px solid black;
            margin-top: 50px;
            padding-top: 50px;

            .header {
                font-family: $serif;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 30px; }

            .container {
                width: calc(100% - 35px); } }

        #response {
            width: 100%;
            padding: 10px 20px;
            margin-top: 60px;
            background-color: black;
            color: white;
            font-size: 26px;
            border: none;
            cursor: pointer;
            line-height: 30px; } } }

@media (max-width: 600px) {
    .App {
        .form {
            .valuation {
                .input {
                    width: 100%; } } } } }
