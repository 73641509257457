.container {
    display: inline-block;
    vertical-align: top;
    line-height: 23px;
    width: 20%;
    min-width: 200px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    // font-size: 22px
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; } }

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #eee;
    border: 1px solid black;

    .container:hover input ~ & {
        background-color: #ccc; }

    .container input:checked ~ & {
        background-color: black; }

    &:after {
        content: "";
        position: absolute;
        display: none; }

    .container input:checked ~ &:after {
        display: block; }

    .container &:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); } }
